export function recpasHeaderEventFunc() {
	let recpasTop = $('.recpas').length;

	if (recpasTop) {
		let scrollHeight = $('.mainview').height();

		window.addEventListener('resize', () => {
			scrollHeight = $('.mainview').height();
		}, false);

		if ($(window).scrollTop() > scrollHeight) {
			$('.recpas #header').addClass('show');
			$('#recpasFixContent').addClass('show');
		} else {
			$('.recpas #header').removeClass('show');
			$('#recpasFixContent').removeClass('show');
		}
	}
}

export function recpasFooterEventFunc() {
	let recpasTop = $('.recpas').length;

	if (recpasTop) {
		let fixContentHeight = $('#recpasFixContent').height();
		window.addEventListener('resize', () => {
			fixContentHeight = $('#recpasFixContent').height();
			$('#recpasFooter').css('padding-bottom', fixContentHeight);
		}, false);

		$('#recpasFooter').css('padding-bottom', fixContentHeight);
	}
}

export function recpasScrollEventFunc() {
	let recpasTop = $('.recpas').length;

	if (recpasTop) {
		$(".fadeIn").each(function () {
			var hit = $(this).offset().top;
			var scroll = $(window).scrollTop();
			var wHeight = $(window).height();

			if (scroll > hit - wHeight + wHeight / 200) {
				$(this).addClass("active");
			}
		});
	}
}

export function recpasEventFunc() {
	let recpasTop = $('.recpas').length;

	if (recpasTop) {
		let mainviewImg =$('.mainview .imgBox');
		let mainviewTxt =$('.mainview .txtDecorationBox');
		let mainviewLine =$('.mainview .lineAnimation');
		const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))

		wait(0)
			.then(() => {
				mainviewImg.addClass('active');
				mainviewTxt.addClass('active');
				return wait(900)
			})
			.then(() => {
				mainviewLine.addClass('active');
				return wait(0)
			})

			var particles = Particles.init({
				selector: '.background',
				maxParticles: 80,
				sizeVariations: 5,
				color: ['#f0f0f0', '#d5d5d5', '#d5d5d5'],
				connectParticles: true,
				responsive: [
					{
						breakpoint: 768,
						options: {
							maxParticles: 50,
						}
					},{
						breakpoint: 455,
						options: {
							maxParticles: 30,
						}
					}
				]
			});

			let scrollHeight = $('.mainview').height();
			window.addEventListener('resize', () => {
				scrollHeight = $('.mainview').height();
			}, false);

			$(window).on("scroll touchmove", function(){
				if ($(window).scrollTop() > scrollHeight) {
					$('#recpasFixContent').stop();
					$('#recpasFixContent').css('display', 'none').delay(300).fadeIn('500');
					// $('#recpasFixContent').removeClass('show').delay(500).queue(function(next){
					// 	$(this).addClass('show');
					// 	next();
					// });
				}
			});
	}
}