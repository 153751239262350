import {
    indResizeFunc,
    responsibleCheck,
} from './_resizecheck';

//変数名(要素名を記載)
var resizecheck, header, mainContent, backLayer, subNavigations, searchForm, globalmenusColumn;

header = '#header';
mainContent = '#mainContent';
backLayer = '#backLayer';
subNavigations = '#subNavigations';
searchForm = '#searchForm';
globalmenusColumn = '.globalmenusColumn';

export function headerHeightFunc() {
    var eleHeight, LayerHeight, spHomeHeight, logo, logoHeight, spHomeHeightTop;
    indResizeFunc();
    logo = '.logo';
    //ヘッダーの高さを取得
    eleHeight = $(header).outerHeight(true);
    logoHeight = $(logo).outerHeight(true);
    logoHeight = Math.round(logoHeight);
    logoHeight = parseInt(logoHeight);
    //検索Formの高さ取得
    $(searchForm).css('height', logoHeight);
    spHomeHeight = $('.spHome').outerHeight(true);
    spHomeHeight = Math.round(spHomeHeight);
    spHomeHeight = parseInt(spHomeHeight);
    //sp時のサブナビゲーション&ハンバーガーメニューの調整
    if (responsibleCheck == '2') {
        $(mainContent).css('padding-top', logoHeight);
        $('.spAdjustColumn').css('padding-top', logoHeight);
        $(globalmenusColumn).css('height', logoHeight);

        spHomeHeightTop = logoHeight - spHomeHeight;
        $('.spHome').css({
            'top':spHomeHeightTop,
            'background-color':'#009a7b',
        });
        $('.globalMenus').css('padding-top', spHomeHeight);
    } else {
        $(searchForm).css('height', eleHeight);
        $(mainContent).css('padding-top', eleHeight);
    }
}

//transformYだけを取得
var values, matrix, resMatrix;
export function transformValueFunc(e) {
    values = e.split('(')[1];
    values = values.split(')')[0];
    values = values.split(', ');
    matrix = {
        'transform-y': values[5]
    };
    resMatrix = parseInt(matrix['transform-y']);
    return resMatrix;
}
//符号値を取得
export function signFunc(n) {
    return !(n < 0) - !(n > 0);
}


//Scroll時のヘッダー処理
var scrollingIncrement, languages, globalMenus;
languages = '.languages';
globalMenus = '.globalMenus';
export function headerScrollFunc() {
    var props,resTransY,resSign
    indResizeFunc();
    if (responsibleCheck != 2) {
        scrollingIncrement = $(window).scrollTop();
        if (scrollingIncrement > 150) {
            $(languages).addClass('none');
            $(globalMenus).addClass('active');
        } else {
            $(languages).removeClass('none');
            $(globalMenus).removeClass('active');
        }
        
        //サブナビが現在表示されているかどうか
        props = $(subNavigations).css('transform');
        resTransY = transformValueFunc(props);
        //符号を取得
        resSign = signFunc(resTransY);
        if(resSign == '1'){
            $(subNavigations).css('z-index', '-1');
            setTimeout(function(){
                $(subNavigations).css('transform', 'translateY(-100%)');
            },200);
        }
    }else{
        $(languages).removeClass('none');
        $(globalMenus).removeClass('active');
    }
}

//サブナビゲーション
export function globalSubNavFunc() {
    indResizeFunc();
    var jsSubOn, resTransY, props, resSign, headerHeight;
    jsSubOn = '#jsSubOn';

    //事業案内をクリックした場合の処理
    $(jsSubOn).on('click', function () {
        if (responsibleCheck != 2) {
            //ヘッダーの高さを取得
            headerHeight = $(header).outerHeight(true);
            //サブナビが現在表示されているかどうか
            props = $(subNavigations).css('transform');
            resTransY = transformValueFunc(props);
            //符号を取得
            resSign = signFunc(resTransY);
            if (resSign == '-1') {
                $(subNavigations).css('transform', 'translateY(' + headerHeight + 'px)');
                setTimeout(function(){
                    $(subNavigations).css('z-index', '1');
                },700);
            } else {
                $(subNavigations).css('z-index', '-1');
                setTimeout(function(){
                    $(subNavigations).css('transform', 'translateY(-100%)');
                },200);
            }
        } else {
            $(subNavigations).slideToggle(700).prev().find('.txt').toggleClass('active');
        }
    });
}

//検索処理
export function searchFunc() {
    indResizeFunc();
    var JsSearch, props, resTransY, resSign;
    JsSearch = '#JsSearch';
    $(JsSearch).on('click', function () {
        props = $('.formBox').css('transform');
        //transformYだけを取得
        resTransY = transformValueFunc(props);
        //符号を取得
        resSign = signFunc(resTransY);
        if (resSign == '-1' || resSign == '0') {
            $('.formBox').css('transform', 'translateY(100%)');
        } else {
            $('.formBox').css('transform', 'translateY(0)');
        }
    });
}


//SP時　Menuボタンを押下時の設定
export function spGlobalNavFunc() {
    var spMenuBtn, props, resTransY, resSign, spAdjustColumn, scrollpos;
    //spMenuBtn = '.spMenuBtn';
    spMenuBtn = '#spBtn';
    spAdjustColumn = '.spAdjustColumn';
    //spMenuBtn = document.getElementById('spBtn').contentDocument;

    $(spMenuBtn).on('click', function () {
        //サブナビが現在表示されているかどうか
        $(this).toggleClass('active');
        props = $('.spAdjustColumn').css('transform');
        //transformYだけを取得
        resTransY = transformValueFunc(props);
        //符号を取得
        resSign = signFunc(resTransY);
        if (resSign == '-1') {
            $('.spHome').css('transform', 'translateY(100%)');
            $(spAdjustColumn).css({
                transition: '.7s',
                transform: 'translateY(0)',
                opacity: 1,
            });
            scrollpos = $(window).scrollTop();
            $('body').addClass('fixedBody').css({
                'top': -scrollpos
            });
        } else {
            $(spAdjustColumn).css({
                transition: '.7s',
                transform: 'translateY(-100%)',
                opacity: 0,
            });
            $('.spHome').css('transform', 'translateY(0)');
            $('body').removeClass('fixedBody').css({
                'top': 0
            });
            window.scrollTo(0, scrollpos);
        }
    });
}