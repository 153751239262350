
import {
    indResizeFunc,
    responsibleCheck,
} from './_resizecheck';


//レスポンシブによる初期化やClass外し
export function responsResetFunc() {
    var resizecheck,hGlobal,subNav,globalMenu,spHome,spAdjustColumn;
    indResizeFunc();
    //resizecheck = $('#responsibleCheck').width();
    spAdjustColumn = '.spAdjustColumn';
    subNav = '#subNav';
    globalMenu = '.globalMenu';
    spHome = '.spHome';
    var dlList;
    dlList = 'dl.list';

    if(responsibleCheck != 2) {
        //ヘッダーリセット処理
        $('.spAdjustColumn,.globalMenus,.spHome,.globalmenusColumn').removeAttr('style');

        // footer処理
        $(dlList).find('dt').removeClass('open');
        $(dlList).find('dd').removeAttr('style');
    }
}

//サブナビゲーション処理
export function subNavigationsFunc() {
    var naviColumn,navListLayout,flag;
    naviColumn = '.naviColumn';
    navListLayout = '.navListLayout';
    $(naviColumn).on('click', function () {
        $(this).toggleClass('open');
        flag = $(navListLayout).css('display');
        if(flag == 'none'){
            $(navListLayout).slideDown(500);
        }else{
            $(navListLayout).slideUp(500);
        }
    });
}

// 事業所所在地のアコーディオン
export function underAccordionFunc() {
    indResizeFunc();
    $('.underPageAccordion dt').on('click', function() {
        $(this).toggleClass('open');
        $(this).next().slideToggle(500);
    });
}
