// jQueryプラグイン
import 'jquery.easing';
import {
    indResizeFunc,
    responsibleCheck,
} from './_resizecheck';
/* ----------------------------------------------------------------------------------
ページ内リンクのアニメーション
---------------------------------------------------------------------------------- */
export function pageLinkAnimationFunc() {
    var jsTop;
    jsTop = '#jsTop';
    $(jsTop).on('click', function () {
        $('body,html').animate({
            scrollTop: 0
        }, 700, 'easeInOutQuint');
        return false;
    });
}

export function pageLinkNavFunc() {
    //URLのハッシュ値を取得
    var urlHash = location.hash;
    //ハッシュ値があればページ内スクロール
    if (urlHash) {
        //スクロールを0に戻す
        $('body,html').stop().scrollTop(0);
        setTimeout(function () {
            //ロード時の処理を待ち、時間差でスクロール実行
            scrollToAnker(urlHash);
        }, 100);
    }

    var href, hash, target, position, eleHeight, scrollToAnker;
    $('a[href^="#"]').click(function () {
				if($(this).hasClass('originalJs')){
					/*用語集専用*/
					href = $(this).attr("href");
					hash = $(href == "#" || href == "" ? 'html' : href);
					scrollToAnkerWord(hash);
					return false;
				}else{
					href = $(this).attr("href");
					hash = $(href == "#" || href == "" ? 'html' : href);
					scrollToAnker(hash);
					return false;
				}
        
    });

    function scrollToAnker(hash) {
        eleHeight = $(header).outerHeight(true);
        target = $(hash);
        position = target.offset().top;
        $("html, body").animate({
            scrollTop: position - eleHeight
        }, 700, "easeInOutQuint");
        return false;
    }
	
	function scrollToAnkerWord(hash) {
		let navHeight = $('.ListTitOrderLayout').outerHeight(true);
		console.log(navHeight);
        eleHeight = $(header).outerHeight(true);
        target = $(hash);
        position = target.offset().top;
        $("html, body").animate({
            scrollTop: position - eleHeight - navHeight
        }, 700, "easeInOutQuint");
        return false;
    }
    
    var url = $(location).attr('href');
      if (url.indexOf("?id=") != -1) {
        eleHeight = $(header).outerHeight(true);
        var id = url.split("?id=");
        var $target = $('#' + id[id.length - 1]);
        if ($target.length) {
          var pos = $target.offset().top - eleHeight - 40;
          $("html, body").animate({
            scrollTop: pos
          }, 1000, "easeInOutQuint");
        }
      }
    
}