/* ----------------------------------------------------------------------------------
import読み込み
--------------------------------------------------------------------------------- */
import {
	redirectFunc
} from './common/_redirect';
import {
	responsResetFunc,
	subNavigationsFunc,
	underAccordionFunc
} from './common/_common';
import {
	hoverFunc,
	telLinkFunc,
	uaZoomFunc
} from './common/_hoverFunc';
import {
	indResizeFunc
} from './common/_resizecheck';
import {
	headerHeightFunc,
	headerScrollFunc,
	searchFunc,
	spGlobalNavFunc,
	globalSubNavFunc,
} from './common/_header';
import {
	footerFunc
} from './common/_footer';
import {
	heightLine,
	heightLineSetFunc,
	heightLineFunc
} from './common/_heightLine';
import {
	pageLinkAnimationFunc,
	pageLinkNavFunc,
} from './common/_pageLinkAnimation';
import {idxFunc} from './pages/_index';
import {
	pararaFunc
} from './common/_parara';
//import {ajaxIdxBtn} from './common/_ajaxBtn';
import {
	singleBtnFunc
} from './pages/_single';
//import {onestopFunc} from './pages/_onestop';
import {
	newsAllFunc,
	seminarAllFunc,
	reportAllFunc,
	accordionSetFunc,
	autoFunc,
} from './common/_individualProcessing';
import {
	vivusFunc,
	svgColorFunc
} from './common/_svgVivus';
import {
	wordNavFunc
} from './pages/_word';
import {
	casbeeFunc
} from './pages/_casbee';

import {
	reportFunc
} from './pages/_report';

import {
	recpasHeaderEventFunc,
	recpasFooterEventFunc,
	recpasScrollEventFunc,
	recpasEventFunc,
} from './pages/_recpasEvent';

import {
	webSeminarFunc,
} from './pages/_webseminar';
/* ----------------------------------------------------------------------------------
通常実行
---------------------------------------------------------------------------------- */
//リダイレクト処理
//redirectFunc();
//リサイズチェック
indResizeFunc();
singleBtnFunc();
/* ----------------------------------------------------------------------------------
window実行
---------------------------------------------------------------------------------- */
// ロード時
$(window).on('load', function () {
	//ヘッダー関連
	headerHeightFunc();
	searchFunc();
	spGlobalNavFunc();
	globalSubNavFunc();
	//フッター関連
	footerFunc();

	//サブナビゲーション
	subNavigationsFunc();
	// アコーディオン
	underAccordionFunc();

	//高さJS
	heightLine();
	heightLineSetFunc();

	//アンカーリンク
	pageLinkAnimationFunc();
	pageLinkNavFunc();

	//パララックスアニメーション
	//pararaFunc();
	//news処理
	newsAllFunc();
	//seminar処理
	seminarAllFunc();
	//レポート処理
	reportAllFunc();

	//アコーディオン系
	accordionSetFunc();
	autoFunc();
	reportFunc();
	//SVG
	vivusFunc();
	svgColorFunc();

	//(リセット処理)
	responsResetFunc();
	
	//各ページ
    idxFunc();
    /*画像モーダル*/
    casbeeFunc();
	/*用語集追従ナビゲーション*/
	wordNavFunc();

	recpasHeaderEventFunc();
	recpasFooterEventFunc();
	recpasEventFunc();

	webSeminarFunc();
});

// リサイズ時
$(window).on('resize', function () {
	//リサイズチェック
	indResizeFunc();
	//ヘッダー関連
	headerHeightFunc();

	//高さ調整
	heightLineFunc();

	//共通処理
	//(リセット処理)
	responsResetFunc();

	//svg関連
	svgColorFunc();
	
	//wordNavFunc();
});

// スクロール時
$(window).on('scroll', function () {
	headerScrollFunc();
	recpasHeaderEventFunc();
	recpasScrollEventFunc();
});


/* ----------------------------------------------------------------------------------
ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
---------------------------------------------------------------------------------- */
var uaFlag = 0;
if ((
		navigator.userAgent.indexOf('iPhone') > 0) ||
	(navigator.userAgent.indexOf('iPod') > 0) ||
	(navigator.userAgent.indexOf('iPad') > 0) ||
	(navigator.userAgent.indexOf('Android') > 0) ||
	(navigator.userAgent.indexOf('BlackBerry') > 0) ||
	(navigator.userAgent.indexOf('PlayBook') > 0) ||
	(navigator.userAgent.indexOf('Kindle') > 0) ||
	(navigator.userAgent.indexOf('Silk') > 0) ||
	(navigator.userAgent.indexOf('Windows Phone') > 0) ||
	(navigator.userAgent.indexOf('Nokia') > 0)
) {
	// スマホ・タブレット時の処理
	uaFlag = 1;

} else {
	// PC時の処理
	uaFlag = 3;
	hoverFunc();
	telLinkFunc();
	uaZoomFunc();
}