export function casbeeFunc() {
	let modalBtn = $('.modalBtn');
    let jsModal = $('.jsModal');
    let modalContent = $('.modalContent');
    let pointScrollY;
    let pointScrollYChange;
    
    window.addEventListener('scroll', function() {
		pointScrollY = $(window).scrollTop();
	});
	
	modalBtn.on('click', function () {
        modalContent.fadeIn();
        $('.close').fadeIn();
        jsModal.fadeIn();
        // 背景固定
		$('body').css({position: 'fixed','width': '100%',top: -pointScrollY});
        pointScrollYChange = $('body').css('top'); // ここでtop値を取得
	});
    
    $('.close').on('click',function(){
        modalContent.fadeOut();
        $('.close').fadeOut();
        setTimeout(function(){
             jsModal.fadeOut();
        },400);
        $('body').attr({style:''});
        $('html,body').animate({scrollTop: parseInt(pointScrollYChange) * -1}, 0);
        return false;
    });
}