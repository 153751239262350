import * as Vivus from 'vivus';
//import Snap from 'snapsvg';
import {
    indResizeFunc,
    responsibleCheck,
} from './_resizecheck';
export function vivusFunc() {
    indResizeFunc();
    /*********矢印アイコンアニメーション***********/
    /*
        aタグまたはアニメーションをかけたい要素の大本にclass="svgAnime";
        objectタグには必ずclass="objAnime"を付与してください
    */
    var svgAnime, objAnime, objAnimeEls, index;
    svgAnime = '.svgAnime';
    objAnime = 'objAnime';
    var objAnimeEls = document.getElementsByClassName(objAnime);
    $(svgAnime).hover(function () {
        index = $(svgAnime).index(this);
        new Vivus(objAnimeEls[index], {
            duration: 20,
            type: 'oneByOne',
        });
    }, function () {
        //何もしない
    });
    /*********矢印アイコンアニメーション***********/

    /*********検索ボタンicon***********/
//    var iconSearch, JsSearch;
//    JsSearch = '#JsSearch';
//    iconSearch = '#iconSearch';
//    var searchIcon = Snap(iconSearch);
//
//    $(JsSearch).on('click', function () {
//        $(this).toggleClass('open');
//        if ($(this).hasClass('open')) {
//            searchIcon.animate({
//                'fill': '#009a7b', //塗りつぶし色
//            }, 500);
//        } else {
//            searchIcon.animate({
//                'fill': '#000', //塗りつぶし色
//            }, 500);
//        }
//    });
    /*********検索ボタンicon***********/

    //    var spHhome, spMenuBtn;
    //    spHhome = '#spHhome';
    //    spMenuBtn = '#spBtn';
    //    var snapSpHhome = Snap(spHhome);
    //    var spMenuBtn = document.getElementById('spBtn').contentDocument;
    //    $(spMenuBtn).on('click', function () {
    //        snapSpHhome.animate({
    //            'fill': '#fff', //塗りつぶし色
    //        }, 500);
    //    });

//    var s = Snap("#iconSpHome");
//    var g = s.group();
//    Snap.load("/images/common/icon_home.svg", function (loadedFragment) {
//        g.append(loadedFragment);
//        g.attr({
//            fill: "#fff",
//        });
//    });
//
//    var s2 = Snap("#IconBreadHome");
//    var g2 = s2.group();
//    Snap.load("/images/common/icon_home.svg", function (loadedFragment) {
//        g2.append(loadedFragment);
//        g2.attr({
//            fill: "#009a7b",
//        });
//    });


    /*********オフィスプライス　mailアイコン***********/
    //    var priceMail = Snap("#price");
    //    var priceMailContent = priceMail.node.contentDocument;
    //    var sPriceMail = Snap( priceMailContent.firstChild );
    //    var rPriceMail = sPriceMail.select('path');
    //    rPriceMail.attr({
    //        fill: "#009a7b",
    //    });

    //    

    //    var s3 = Snap("#target");
    //    var world;
    //    var hoge;
    //    Snap.load("/images/office_price_index/icon_mail.svg", function (f) {
    //        s3.append(f);
    //        hoge = s3.select('svg');
    //        hoge.attr({
    //            viewBox: "0 0 25 19",
    //            width:"25",
    //            height:"19",
    //        });
    //        world = s3.select('path')
    //        world.attr({
    //            fill: "#009a7b",
    //        });
    //    });

    /*********メールアイコン***********/
    //ヘッダー
    //    var iconHContact, sHContact;
    //    iconHContact = '#iconHContact';
    //    sHContact = Snap(iconHContact);
    //
    //    sHContact.attr({
    //        fill: "#009a7b",
    //    });
    //
    //    //footer
    //    var iconFContact, sFContact;
    //    iconFContact = '#iconFContact';
    //    sFContact = Snap(iconFContact);
    //
    //    sFContact.attr({
    //        fill: "#fff",
    //    });
    /*********メールアイコン***********/
}
var resizeTimer = null;
var timer = false;
export function svgColorFunc() {
    indResizeFunc();

    //    
    //    /*********メールアイコン***********/
    //    //ヘッダー
    //    var iconHContact, sHContact;
    //    iconHContact = '#iconHContact';
    //    sHContact = Snap(iconHContact);
    //    if (responsibleCheck != 2) {
    //        console.log('pcだよ');
    //        sHContact.attr({
    //            fill: "#009a7b",
    //        });
    //    } else {
    //        console.log('spだよ');
    //        sHContact.attr({
    //            fill: "#ffffff",
    //        });
    //    }
}