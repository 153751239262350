
export function webSeminarFunc() {
	//同意で送信可能
	if ($("#webSeminar").length > 0){
		$('#submit').prop('disabled', true);
		$('#agree').on('change', function(){
			if ($(this).is(':checked')) {
				$('#submit').prop('disabled', false);
			} else {
				$('#submit').prop('disabled', true);
			}
		  });
	}

	$('.archive .youtubeThumbnail').each(function (i) {
    var $_this = $(this);
		var wistiaId = $_this.attr("data-wistiaId");
		var baseUrl = "https://fast.wistia.com/oembed/?url=";
		var accountUrl = encodeURI("https://home.wistia.com/medias/");
		var mediaHashedId = wistiaId;
	
		if(wistiaId){
			function getThumbnailUrl(hashedId, callback) {
			$.getJSON(baseUrl + accountUrl + hashedId + "&format=json&callback=?", callback);
			}
			function parseJSON(json) {
				$_this.children('img').attr('src',json.thumbnail_url);
			};
			getThumbnailUrl(mediaHashedId, parseJSON);
		}
	});
}