export function wordNavFunc() {
	if ($('.ListTitOrderLayout')[0]) {
		let navPos = $('.ListTitOrderLayout').offset().top;
		let navPosH = $('.ListTitOrderLayout').outerHeight(true);
		let headerH = $(header).outerHeight(true);
		let getMarB = parseInt($('.glossaryListLayout').css('margin-bottom'), 10);
		$(window).on('scroll', function () {
			var t = $('.targetBtn').offset().top; // ターゲットの位置取得
			var p = t - $(window).height(); // 画面下部からのターゲットの位置

			if ($(this).scrollTop() + headerH >= navPos) {
				$('.ListTitOrderLayout').addClass('posFid');
				$('.repeatListLayout').css('padding-top', navPosH + getMarB);
				$('.posFid').css('top', headerH);
			} else {
				$('.ListTitOrderLayout').removeClass('posFid');
				$('.repeatListLayout').css('padding-top', 0);
			}

			if ($(window).scrollTop() > p) {
				$('.ListTitOrderLayout').fadeOut();
			} else {
				$('.ListTitOrderLayout').fadeIn();
			}

		});
	}
}