import Swiper from 'swiper/bundle';

let indexTop = $('#idx').length;

export function idxFunc() {
    if (indexTop) {
        //スワイプ実行処理
        let topOptions = {
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            speed: 3000,
        };

        let topSwiper = new Swiper('#mainSlider', topOptions);
    }
}